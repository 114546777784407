/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Environment {
    DEVELOPMENT = 'DEVELOPMENT',
    ACCEPTANCE = 'ACCEPTANCE',
    BETA_PRODUCTION = 'BETA_PRODUCTION',
    PRODUCTION = 'PRODUCTION',
}
