import { SilentRequest } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Intent } from '@blueprintjs/core';
import { Log, toStringSafe, TranslateUtil } from '@zorgi-mobile/shared-javascript';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OpenAPI } from '../api/generated';
import image from '../assets/Zorgi.png';
import { EnvContext } from '../context/EnvContext';

export function LoginScreen() {
    const { instance, accounts } = useMsal();
    const { env } = useContext(EnvContext);
    console.log('loginScreen env', env);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const setTokenAndRedirect = (token: string) => {
            OpenAPI.TOKEN = token;
            navigate('customers');
        };

        const acquireTokenWithPopup = async (request: SilentRequest) => {
            try {
                const response = await instance.acquireTokenPopup(request);
                setTokenAndRedirect(response.accessToken);
            } catch (e: unknown) {
                Log.error(toStringSafe(e));
            }
        };

        const acquireTokenSilently = async () => {
            const request = {
                scopes: [env.scope],
                account: accounts[0],
            };
            try {
                const response = await instance.acquireTokenSilent(request);
                setTokenAndRedirect(response.accessToken);
            } catch {
                await acquireTokenWithPopup(request);
            }
        };

        if (isAuthenticated) {
            acquireTokenSilently();
        }
    }, [isAuthenticated, accounts, env, instance, navigate]);

    const authenticate = async () => {
        setLoading(true);
        console.log('authenticate: ', env);
        try {
            await instance.loginRedirect({ scopes: [env.scope] });
        } catch (e: unknown) {
            Log.error(toStringSafe(e));
        }
    };

    return (
        <div style={{ height: '100vh', justifyContent: 'center', display: 'flex' }}>
            <div style={{
                minWidth: 250,
                width: '20%',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }}
            >
                <img alt="logo" style={{ width: '100%' }} src={image} />
                <h1 style={{ textAlign: 'center' }}>{TranslateUtil.translateKey('logistics_app_name')}</h1>
                <Button
                    loading={loading}
                    onClick={() => { authenticate(); }}
                    style={{ float: 'right' }}
                    intent={Intent.PRIMARY}
                >
                    {TranslateUtil.translateKey('login')}
                </Button>
            </div>
        </div>
    );
}
