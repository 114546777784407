export const msalConfig = (clientId: string, tenantId: string) => ({
    auth: {
        clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
});

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
