"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HealthCardColorUtil = void 0;
/**
 * Colors only used in XCare Health Cards (Fiche Sante)
 */
class HealthCardColorUtil {
    static getGray100() { return '#F8F9FA'; }
    static getGray400() { return '#CED4DA'; }
    static getGray500() { return '#ADB5BD'; }
    static getGray600() { return '#6C757D'; }
    static getGray700() { return '#495057'; }
    static getProblem() { return '#48BC23'; }
    static getActiveNotConfirmedProblem() { return '#B6EEA4'; }
    static getEventAllergy() { return '#FED211'; }
    static getDesensitizationAllergy() { return '#4FA9D1'; }
    static getPriorityLow() { return '#4FA9D1'; }
    static getPriorityMedium() { return '#F00'; }
    static getPriorityHigh() { return '#FFD500'; }
    static getInfoBadge() { return 'rgb(79,169,209)'; }
    static getWarningBadge() { return 'rgb(249,176,75)'; }
    static getDangerBadge() { return '#F4780E'; }
    static getImportantEventAllergy() { return 'rgb(215,25,27)'; }
}
exports.HealthCardColorUtil = HealthCardColorUtil;
