"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GraphUtil = void 0;
const TranslateUtil_1 = require("./translation/TranslateUtil");
/**
 * A util for graphs. Depends on highcharts
 */
class GraphUtil {
    /**
     * Get options to configure highcharts
     */
    static getOptions() {
        return {
            global: {
                useUTC: false,
            },
            lang: {
                decimalPoint: ',',
                thousandsSep: '.',
                resetZoom: TranslateUtil_1.TranslateUtil.translateKey('reset_zoom'),
            },
        };
    }
}
exports.GraphUtil = GraphUtil;
