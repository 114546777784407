"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.perfStopAndPrintRecording = exports.perfMarkTime = exports.perfStartRecording = void 0;
/**
 * DO NOT USE IN PRODUCTION
 *
 * Util that can be used to measure time between lines of code and method calls.
 */
const DevCheckUtil_1 = require("./DevCheckUtil");
const Log_1 = require("./Log");
const TextUtil_1 = require("./TextUtil");
const lastRecordedTimes = {};
const timeMarks = {};
function perfStartRecording(key) {
    if (!(0, DevCheckUtil_1.isDevelopment)())
        return;
    lastRecordedTimes[key] = performance.now();
    timeMarks[key] = [];
}
exports.perfStartRecording = perfStartRecording;
function perfMarkTime(key, label, ...parameters) {
    var _a;
    if (!(0, DevCheckUtil_1.isDevelopment)())
        return;
    const lastRecordedTime = lastRecordedTimes[key];
    if (lastRecordedTime == null) {
        return;
    }
    const nowTime = performance.now();
    const duration = nowTime - lastRecordedTime;
    lastRecordedTimes[key] = nowTime;
    (_a = timeMarks[key]) === null || _a === void 0 ? void 0 : _a.push({ label: `${label} ${parameters.map(TextUtil_1.toStringSafe).filter((x) => x).join(', ')}`, duration });
}
exports.perfMarkTime = perfMarkTime;
function perfStopAndPrintRecording(key) {
    var _a;
    if (!(0, DevCheckUtil_1.isDevelopment)())
        return;
    const lastRecordedTime = lastRecordedTimes[key];
    if (lastRecordedTime == null) {
        Log_1.Log.warn('A time recording has not been started yet, tried to stop recording for key', key);
        return;
    }
    const result = ` Performance recording stopped:
== START RECORDING MARKS ==
Key: ${key}
Marks: [
${(_a = timeMarks[key]) === null || _a === void 0 ? void 0 : _a.map(({ label, duration }) => `- Label: ${label}, Duration: ${duration}ms`).join('\n')}
]
== END RECORDING MARKS ==
`;
    Log_1.Log.info(result);
    delete lastRecordedTimes[key];
    delete timeMarks[key];
}
exports.perfStopAndPrintRecording = perfStopAndPrintRecording;
