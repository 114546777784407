/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Device } from '../models/Device';
import type { DeviceCreation } from '../models/DeviceCreation';
import type { DeviceCreationResponse } from '../models/DeviceCreationResponse';
import type { DeviceRegistration } from '../models/DeviceRegistration';
import type { DeviceRegistrationResponse } from '../models/DeviceRegistrationResponse';
import type { DevicesList } from '../models/DevicesList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeviceService {

    /**
     * Start adding a device for authentication.
     * @param requestBody
     * @returns DeviceCreationResponse Device Creation Successfully started
     * @throws ApiError
     */
    public static startAddDevice(
        requestBody?: DeviceCreation,
    ): CancelablePromise<DeviceCreationResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/web/registerDevice',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                409: `Device already exists`,
            },
        });
    }

    /**
     * Register a device
     * @param requestBody
     * @returns DeviceRegistrationResponse OK
     * @throws ApiError
     */
    public static registerDevice(
        requestBody?: DeviceRegistration,
    ): CancelablePromise<DeviceRegistrationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/app/registerDevice',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get device information
     * @param deviceId
     * @returns Device Return state information
     * @throws ApiError
     */
    public static getDeviceState(
        deviceId: string,
    ): CancelablePromise<Device> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Device not found`,
            },
        });
    }

    /**
     * Returns a list of all devices for the specific customer
     * @param customer
     * @returns DevicesList OK
     * @throws ApiError
     */
    public static getDevicesList(
        customer: string,
    ): CancelablePromise<DevicesList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/devices/{customer}',
            path: {
                'customer': customer,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
