import React, { useContext, useMemo, useState } from 'react';

import { Customer } from '../api/generated';

export interface CustomerContextType {
    customer?: Customer;
    availableCustomers: Customer[];
    setCustomer: (customer?: Customer) => void;
    setAvailableCustomers: (customers: Customer[]) => void;
    addAvailableCustomer: (customer: Customer) => void;
}

const defaultValue: CustomerContextType = {
    customer: undefined,
    availableCustomers: [],
    setCustomer: (_customer?: Customer) => undefined,
    setAvailableCustomers: (_customers: Customer[]) => undefined,
    addAvailableCustomer: (_customer: Customer) => undefined,
};

export const CustomerContext = React.createContext<CustomerContextType>(defaultValue);

export function CustomerProvider({ children }: { children: React.ReactNode }) {
    const [customer, setCustomer] = useState<Customer | undefined>(undefined);
    const [availableCustomers, setAvailableCustomers] = useState<Customer[]>([]);

    const addAvailableCustomer = (newCustomer: Customer) => {
        setAvailableCustomers((c) => [...c, newCustomer]);
    };

    const value: CustomerContextType = useMemo(() => ({
        customer,
        setCustomer,
        availableCustomers,
        setAvailableCustomers,
        addAvailableCustomer,
    }), [customer, availableCustomers]);

    return (
        <CustomerContext.Provider
            value={value}
        >
            {children}
        </CustomerContext.Provider>
    );
}

export function useCustomer() {
    return useContext(CustomerContext);
}
