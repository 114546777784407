/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer } from '../models/Customer';
import type { CustomerList } from '../models/CustomerList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerService {

    /**
     * Gets a list of all customers in the system
     * @returns CustomerList OK
     * @throws ApiError
     */
    public static getCustomers(): CancelablePromise<CustomerList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/customers/',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Adds a customer into the system
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static addCustomer(
        requestBody?: Customer,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/customers/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
