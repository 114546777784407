"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ArrayUtil_1 = require("../ArrayUtil");
const BelgianIdentityParser_1 = require("./parsers/BelgianIdentityParser");
const DefaultParser_1 = require("./parsers/DefaultParser");
const GS1Parser_1 = require("./parsers/GS1Parser");
const MobitraceParser_1 = require("./parsers/MobitraceParser");
const NullParser_1 = require("./parsers/NullParser");
/**
 * A class to parse barcodes
 */
class BarcodeParser {
    /**
     * Parsers that are run when a single barcode is provided.
     * @returns {IBarcodeParser[]}
     */
    static getParsers() {
        return [...BarcodeParser.parsers];
    }
    /**
     * @returns {IBarcodeParser[]}
     */
    static getDefaultParsers() {
        return [...BarcodeParser.defaultParsers];
    }
    /**
     * Adds a specific parser to the parsers
     * @param parser
     */
    static addParser(parser) {
        BarcodeParser.parsers.push(parser);
    }
    /**
     * Resets the default specific parsers
     */
    static reset() {
        BarcodeParser.parsers = BarcodeParser.getDefaultParsers();
    }
    /**
     * Parses provided barcodes
     * @param  {string | string[]} input
     * @return {ParsedBarcode[]}
     */
    static parse(input) {
        const barcodes = typeof input === 'string' ? [input] : [...input];
        if (barcodes.length === 0) {
            return BarcodeParser.runParsersOnBarcode(BarcodeParser.emptyListParsers);
        }
        return BarcodeParser.runParsersOnBarcodes(BarcodeParser.getParsers(), barcodes);
    }
    /**
     * Runs parser list on provided barcode list
     * @param   {IBarcodeParser[]} parserList
     * @param   {string[]} barcodes
     * @returns {IBarcodeParser[]}
     */
    static runParsersOnBarcodes(parserList, barcodes) {
        return ArrayUtil_1.ArrayUtil.flatten(barcodes.map((barcode) => BarcodeParser.runParsersOnBarcode(parserList, barcode)));
    }
    /**
     * Runs parser list on provided barcode
     * @param   {IBarcodeParser[]} parserList
     * @param   {string | undefined} barcode
     * @returns {IBarcodeParser[]}
     */
    static runParsersOnBarcode(parserList, barcode) {
        return parserList.reduce((acc, parser) => {
            const parsedBarcode = parser.parse(barcode);
            if (parsedBarcode)
                acc.push(parsedBarcode);
            return acc;
        }, []);
    }
}
exports.default = BarcodeParser;
/**
 * Default parsers used initially and after resets
 */
BarcodeParser.defaultParsers = [new GS1Parser_1.GS1Parser(), new MobitraceParser_1.MobitraceParser(), new BelgianIdentityParser_1.BelgianIdentityParser(), new DefaultParser_1.DefaultParser()];
/**
 * Parsers that are run only if an empty list of barcodes is provided
 */
BarcodeParser.emptyListParsers = [new NullParser_1.NullParser()];
/**
 * Parsers that will be run (can be modified using addParser)
 */
BarcodeParser.parsers = BarcodeParser.getDefaultParsers();
