"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParsedBarcodeTypes = void 0;
/**
 * An enum with all parsed types
 */
var ParsedBarcodeTypes;
(function (ParsedBarcodeTypes) {
    ParsedBarcodeTypes["UNKNOWN"] = "unknown";
    ParsedBarcodeTypes["GS1"] = "gs1";
    ParsedBarcodeTypes["MOBITRACE"] = "mobitrace";
    ParsedBarcodeTypes["GENERIC"] = "generic";
    ParsedBarcodeTypes["BELGIAN_NATIONAL_REGISTER_NUMBER"] = "belgianNationalRegisterNumber";
})(ParsedBarcodeTypes = exports.ParsedBarcodeTypes || (exports.ParsedBarcodeTypes = {}));
