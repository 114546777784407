import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { OpenAPI } from './api/generated';
import { CustomerProvider } from './context/CustomerContext';
import { EnvContext } from './context/EnvContext';
import { ConfigScreen } from './screens/ConfigScreen';
import { CustomersOverviewScreen } from './screens/CustomersOverviewScreen';
import { DevicesOverviewScreen } from './screens/DevicesOverviewScreen';
import { LoginScreen } from './screens/LoginScreen';

function App() {
    const { env, loadingEnv } = useContext(EnvContext);
    useEffect(() => {
        if (OpenAPI.BASE !== env.apiUrl) {
            OpenAPI.BASE = env.apiUrl;
        }
    }, [env]);

    if (loadingEnv) {
        // Do not render the remainder of the app until environment variables have been loaded in.
        return <div />;
    }
    return (
        <CustomerProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LoginScreen />} />
                    <Route path="customers" element={<CustomersOverviewScreen />} />
                    <Route path="devices" element={<DevicesOverviewScreen />} />
                    <Route path="config" element={<ConfigScreen />} />
                </Routes>
            </BrowserRouter>
        </CustomerProvider>

    );
}

export default App;
