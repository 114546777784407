"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringUtils = void 0;
/**
 * A class to handle all sorts of common tasks on Strings.
 */
class StringUtils {
    /**
     * Returns the last character of a string
     * @param {!string} string - A string
     * @return {string} The last character of the string
     */
    static getLastChar(string) {
        return string[string.length - 1];
    }
    /**
     * Returns the first number it finds in a string
     * @param {!string} string - A string
     * @return {number} The first number it finds in the string
     */
    static getStartingNumber(string) {
        const regexResult = string.match('\\d+');
        if (regexResult) {
            return Number.parseFloat(regexResult[0]);
        }
        return null;
    }
}
exports.StringUtils = StringUtils;
