"use strict";
/**
 * Created by michielknevels on 31/10/17.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("../actions/types");
const INITIAL_STATE = {
    show: false,
    locked: false,
    title: null,
    message: null,
    buttons: null,
    level: null,
    disabled: false,
};
/**
 * A reducer to show alerts
 */
exports.default = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types_1.ALERT_SHOW:
            if (!state.disabled) {
                return Object.assign(Object.assign({}, state), { show: true, title: action.payload.title, message: action.payload.message, buttons: action.payload.buttons, level: action.payload.level });
            }
            return state;
        case types_1.ALERT_LOCK:
            if (state.show) {
                return Object.assign(Object.assign({}, state), { show: false, locked: true });
            }
            return state;
        case types_1.ALERT_UNLOCK:
            if (state.locked) {
                return Object.assign(Object.assign({}, state), { show: true, locked: false });
            }
            return state;
        case types_1.ALERT_HIDE:
            return Object.assign({}, INITIAL_STATE);
        case types_1.ALERT_DISABLE:
            return Object.assign(Object.assign({}, state), { disabled: action.payload });
        default:
            return state;
    }
};
