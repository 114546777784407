"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.swapEndianness = void 0;
const Log_1 = require("./Log");
/**
 * Takes a hex encoded string and swaps its endianness.
 *
 * E.g. Input: "D982B5E3", output: "E3B582D9"
 */
function swapEndianness(hexString) {
    if (hexString.length % 2 !== 0) {
        Log_1.Log.warn('swapEndianness warning: attempt to swap endian on uneven sized string');
        return '';
    }
    const result = [];
    for (let i = hexString.length - 2; i >= 0; i -= 2) {
        result.push(hexString[i]);
        result.push(hexString[i + 1]);
    }
    return result.join('');
}
exports.swapEndianness = swapEndianness;
