import { Button, Spinner } from '@blueprintjs/core';
import { Log, toStringSafe, TranslateUtil } from '@zorgi-mobile/shared-javascript';
import React, { useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

import { ConfigService, CustomerConfig, OpenAPI } from '../api/generated';
import { CustomerSelectHOC } from '../components/CustomerSelectHOC';
import { LogisticsNavbar } from '../components/Navbar';
import { useCustomer } from '../context/CustomerContext';

let newConfig: CustomerConfig | null = null;

export function ConfigScreen() {
    const [config, setConfig] = useState<CustomerConfig | null>(null);
    const [savingConfig, setSavingConfig] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { customer } = useCustomer();

    if (!OpenAPI.TOKEN) {
        (window as Window).location = '/' as const;
        return null;
    }

    const loadData = (customerId?: string) => {
        ConfigService.getConfigWeb(customerId || (customer ? customer.id : ''))
            .then((customerConfig) => {
                setConfig(customerConfig);
            })
            .catch((e) => {
                Log.error(toStringSafe(e));
            });
    };

    if (!config && customer) {
        loadData();
    }

    const saveNewConfig = () => {
        if (newConfig) {
            const configToSave = {
                customerConfig: newConfig,
                customer: customer?.id || '',
            };
            setSavingConfig(true);
            ConfigService.setConfig(configToSave)
                .then(() => {
                    setSavingConfig(false);
                    setConfig(null);
                })
                .catch(() => {
                    setSavingConfig(false);
                    setError(TranslateUtil.translateKey('incorrect_config'));
                });
        }
    };

    return (
        <div>
            <LogisticsNavbar
                active="config"
                onCustomerChange={(customerId: string) => {
                    loadData(customerId);
                }}
            />
            <CustomerSelectHOC>
                <div style={{
                    width: '80%',
                    margin: 'auto',
                }}
                >
                    {config ? (
                        <div>
                            <JSONInput
                                error={error ? ({ reason: error, line: 1 }) : undefined}
                                id="a_unique_id"
                                placeholder={config}
                                locale={locale}
                                height="550px"
                                onChange={(data: any) => {
                                    try {
                                        newConfig = JSON.parse(data.json);
                                    } catch (e) {
                                        Log.error(toStringSafe(e));
                                    }
                                }}
                            />
                            <Button loading={savingConfig} onClick={saveNewConfig}>Save</Button>
                        </div>

                    ) : (<div style={{ marginTop: 20 }}><Spinner /></div>)}
                </div>
            </CustomerSelectHOC>

        </div>
    );
}
