import React from 'react';

export interface WebEnvironment {
    clientId: string;
    tenantId: string;
    apiUrl: string;
    scope: string;
}

export interface EnvContextType {
    env: WebEnvironment;
    loadingEnv: boolean;
}

export const defaultWebEnvironment = {
    clientId: 'bab30a1a-ae15-4ad1-829e-acf334a57247',
    tenantId: 'c32cd569-e121-4cb9-b835-6e299a75b32c',
    apiUrl: `${window.location.protocol}//${window.location.hostname}:8080/v1`,
    scope: '',
};

const defaultEnvContext: EnvContextType = {
    env: defaultWebEnvironment,
    loadingEnv: true,
};

export const EnvContext = React.createContext(defaultEnvContext);
