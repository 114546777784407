"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'uuid... Remove this comment to see the full error message
const uuid_1 = require("uuid");
/**
 * A class to hold parsed barcodes
 */
class ParsedBarcode {
    constructor() {
        this.uuid = (0, uuid_1.v4)();
        this.type = null;
        this.original = null;
        this.code = null;
        this.field = null;
        // Mobitrace stuff
        this.for = null;
        this.wildcard = false;
        // GS1 stuff
        this.gtin = null;
        this.sscc = null;
        this.product = null;
        this.lot = null;
        this.prodDate = null;
        this.packDate = null;
        this.safeDate = null;
        this.expDate = null;
        this.serial = null;
        this.count = null;
    }
}
exports.default = ParsedBarcode;
