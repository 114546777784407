"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectUtil = void 0;
/**
 * A util for objects
 */
class ObjectUtil {
    /**
     * returns the first value found in a object
     */
    static getFirst(object) {
        const values = object ? Object.values(object) : [];
        return values.length ? values[0] : null;
    }
}
exports.ObjectUtil = ObjectUtil;
