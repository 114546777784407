"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("../actions/types");
const INITIAL_STATE = {
    active: false,
};
/**
 * A reducer to show loader modals
 */
exports.default = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types_1.MODAL_LOADER_SHOW:
            return Object.assign(Object.assign({}, state), { active: action.payload });
        case types_1.LOGOUT:
            return Object.assign({}, INITIAL_STATE);
        default:
            return state;
    }
};
