import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import 'normalize.css/normalize.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import { EnvProvider } from './components/providers/EnvProvider';
import { MicrosoftProvider } from './components/providers/MicrosoftProvider';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://38105205e4714f61942aeedf7e7d7bd5@o457746.ingest.sentry.io/6250739',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <EnvProvider>
            <MicrosoftProvider>
                <App />
            </MicrosoftProvider>
        </EnvProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
