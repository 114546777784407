import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React, { ReactNode, useContext, useEffect } from 'react';

import { msalConfig } from '../../authConfig';
import { defaultWebEnvironment, EnvContext } from '../../context/EnvContext';

let msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig(defaultWebEnvironment.clientId, defaultWebEnvironment.tenantId));

export function MicrosoftProvider(props: { children: ReactNode }) {
    const { env } = useContext(EnvContext);

    useEffect(() => {
        const authConfig = msalInstance.getConfiguration().auth;
        console.log('MicrosoftProvicer useEffect', authConfig);
        if (authConfig.clientId !== env.clientId || !authConfig.authority.includes(env.tenantId)) {
            msalInstance = new PublicClientApplication(msalConfig(env.clientId, env.tenantId));
        }
    }, [env]);

    return (
        <MsalProvider instance={msalInstance}>
            {props.children}
        </MsalProvider>
    );
}
