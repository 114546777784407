/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ActionType {
    APO_HALF_FULL = 'APO_HALF_FULL',
    APO_WAREHOUSE = 'APO_WAREHOUSE',
    APO_TRACEABLE_CONTROL = 'APO_TRACEABLE_CONTROL',
    APO_INVENTORY = 'APO_INVENTORY',
    APO_INVENTORY_CUPBOARD = 'APO_INVENTORY_CUPBOARD',
    ECO_HALF_FULL = 'ECO_HALF_FULL',
    ECO_WAREHOUSE = 'ECO_WAREHOUSE',
    ECO_TRACEABLE_CONTROL = 'ECO_TRACEABLE_CONTROL',
    ECO_INVENTORY = 'ECO_INVENTORY',
    ECO_ORDER = 'ECO_ORDER',
    ECO_ENDOWMENT = 'ECO_ENDOWMENT',
    ERP_ISSUED = 'ERP_ISSUED',
    ERP_HALF_FULL = 'ERP_HALF_FULL',
    ERP_HALF_FULL_AMOUNT = 'ERP_HALF_FULL_AMOUNT',
    ERP_PICKING = 'ERP_PICKING',
    ERP_RECEPTION = 'ERP_RECEPTION',
    ERP_DATA_SYNC = 'ERP_DATA_SYNC',
    ERP_INVENTORY = 'ERP_INVENTORY',
}
