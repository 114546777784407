import { Log, toStringSafe } from '@zorgi-mobile/shared-javascript';
import axios from 'axios';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { defaultWebEnvironment, EnvContext } from '../../context/EnvContext';

export function EnvProvider(props: { children: ReactNode }) {
    const [loading, setLoading] = useState(true);
    const [env, setEnv] = useState(defaultWebEnvironment);

    useEffect(() => {
        const readEnv = async () => {
            setLoading(true);
            const success = await loadEnv('./env.json');
            if (!success && process.env.NODE_ENV === 'development') {
                await loadEnv('./env.dev.json');
            }
            setLoading(false);
        };
        readEnv();
    }, []);

    async function loadEnv(envPath: string): Promise<boolean> {
        console.log('loadEnv: ', envPath);
        try {
            const response = await axios.get(envPath);
            console.log('loadEnv response', JSON.stringify(response));
            if (response && response.data) {
                const {
                    API_URL, CLIENT_ID, TENANT_ID, SCOPE,
                } = response.data;
                if (!API_URL || !CLIENT_ID || !TENANT_ID || !SCOPE) {
                    return false;
                }
                setEnv({
                    apiUrl: API_URL,
                    clientId: CLIENT_ID,
                    tenantId: TENANT_ID,
                    scope: SCOPE,
                });
                return true;
            }
        } catch (e) {
            Log.error('readEnv', toStringSafe(e));
        }
        return false;
    }

    const contextValue = useMemo(() => ({
        env,
        loadingEnv: loading,
    }), [env, loading]);

    return <EnvContext.Provider value={contextValue}>{props.children}</EnvContext.Provider>;
}
