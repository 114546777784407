"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOGOUT = exports.MODAL_LOADER_SHOW = exports.ALERT_DISABLE = exports.ALERT_UNLOCK = exports.ALERT_LOCK = exports.ALERT_HIDE = exports.ALERT_SHOW = exports.LANGUAGE_SET = void 0;
/**
 * Redux action to set the language
 */
exports.LANGUAGE_SET = 'language_set';
/**
 * Redux action to show a alert
 */
exports.ALERT_SHOW = 'alert_show';
/**
 * Redux action to hide a alert
 */
exports.ALERT_HIDE = 'alert_hide';
/**
 * Redux action to lock a alert
 */
exports.ALERT_LOCK = 'alert_lock';
/**
 * Redux action to unlock a alert
 */
exports.ALERT_UNLOCK = 'alert_unlock';
/**
 * Redux action to disable all alerts
 */
exports.ALERT_DISABLE = 'alert_disable';
/**
 * Redux action to show/hide a popup loader
 */
exports.MODAL_LOADER_SHOW = 'modal_loader_show';
/**
 * Redux action to reset states
 */
exports.LOGOUT = 'logout';
