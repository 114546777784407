"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Shared = exports.StepComponent = void 0;
/**
 * The StepComponent that can be used by other components
 */
// eslint-disable-next-line import/no-mutable-exports
exports.StepComponent = null;
/**
 * A class where projects can set data that the submodule can use.
 */
class Shared {
    /**
     * Sets the StepComponent. This can be used in screens.
     * Done this way so screens can be reused on React and React native
     * @param {React.Component} component - The step component
     */
    static setStepComponent(component) {
        exports.StepComponent = component;
    }
}
exports.Shared = Shared;
