"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorUtil = void 0;
/**
 * Created by michielknevels on 28/09/17.
 */
const validate_color_1 = require("validate-color");
/**
 * A util for colors
 */
class ColorUtil {
    /**
     * Converts a color word string to a color hex string
     */
    static getColorFromCode(code) {
        switch (code) {
            case 'green': return ColorUtil.getGreen();
            case 'orange': return ColorUtil.getWarningColor();
            case 'red': return ColorUtil.getErrorColor();
            case 'pink': return ColorUtil.getPink();
            case 'light-blue': return ColorUtil.getLightBlue();
            case 'blue': return ColorUtil.getInfoBlue();
            case 'grey': return ColorUtil.getNeutralColor();
            case 'dark-grey': return ColorUtil.getDarkGrey();
            default: return ColorUtil.getTransparent();
        }
    }
    /**
     * Takes a color string and returns a supported valid one. We support:
     * - hex color strings: #FFF, #23F1A6, etc.
     * - rgb & rgba color strings: rgb(230, 86, 34), rgba(255, 0, 255, 0.6), etc.
     * - hsl & hsla color strings: hsl(360, 100%, 80%), hsla(270, 45%, 67%, 1.0), etc.
     * - named color strings: 'BLUE', 'green', 'GreenYellow', etc.
     * All other types of color strings are not supported by react-native. So for the sake of keeping our entire ecosystem
     * tidy we restrict ourselves to these types of color.
     *
     * If a named string is given we return the lowercased version because react-native only supports lowercased named color strings.
     * For future reference: https://reactnative.dev/docs/colors
     *
     * @param colorStr a string which may or may not be a valid color string
     * @return If a valid hex, rgb, hsl color is given: return it. Named color string: return lowercased. Invalid color string: return null.
     */
    static toValidColorString(colorStr) {
        if ((0, validate_color_1.validateHTMLColorName)(colorStr)) {
            return colorStr.toLowerCase();
        }
        if ((0, validate_color_1.validateHTMLColorHex)(colorStr) || (0, validate_color_1.validateHTMLColorRgb)(colorStr) || (0, validate_color_1.validateHTMLColorHsl)(colorStr)) {
            return colorStr;
        }
        return null;
    }
    static getSuccessColor(opacity) { return `rgba(19,145,141,${opacity || 255})`; }
    static getLightWarningColor() { return '#E8D91A'; }
    static getWarningColor() { return '#FFA818'; }
    static getErrorColor() { return '#EC3D40'; }
    static getLighterNeutralColor() { return '#DDD'; }
    static getNeutralColor() { return '#D2D2D2'; }
    static getDisabledColor() { return '#b5b5b5'; }
    static getGreenColor() { return 'rgba(113, 192, 26, 1)'; }
    static getBackgroundColor() { return '#FFF'; }
    static getPrimaryColor() { return '#000'; }
    static getInversePrimaryColor() { return '#FFF'; }
    static getSecondaryColor() { return '#9B9B9B'; }
    static getButtonColor() { return '#E0E0E0'; }
    static getDarkWhite() { return '#f5f5f5'; }
    static getLightGrey() { return '#e5e5e5'; }
    static getVeryLightGrey() { return 'rgb(248,249,250)'; }
    static getLighterGrey() { return 'rgb(244,244,244)'; }
    static getDarkGrey() { return '#777'; }
    static getBorderColor() { return 'rgba(0, 0, 0, 0.12)'; }
    static getNotificationBackgroundColor() { return 'rgba(240,240,240,0.99)'; }
    static getTransparent() { return 'transparent'; }
    static getBlack() { return '#000'; }
    static getLightBlack() { return '#0F0F0F'; }
    static getWhite() { return '#fff'; }
    static getPink() { return '#9E2484'; }
    static getDarkGreen() { return '#024442'; }
    static getKhaki() { return '#354A21'; }
    static getOrange() { return '#F4780E'; }
    static getLightOrange() { return '#FFDF9E'; }
    static getLightBlue() { return '#83BDF7'; }
    static getDarkBlue() { return '#0666C4'; }
    static getGreen() { return '#5CB85C'; }
    static getPopupText() { return 'rgba(0, 0, 0, 0.54)'; }
    static getInfoBlue() { return '#4a90e2'; }
}
exports.ColorUtil = ColorUtil;
