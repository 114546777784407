"use strict";
/**
 * Created by michielknevels on 31/10/17.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDisableAlerts = exports.unlockAlert = exports.lockAlert = exports.hideAlert = exports.showAlert = void 0;
const types_1 = require("./types");
/**
 * Creates a redux action to show a alert
 * @param title - The translated title
 * @param message - The translated message
 * @param level - The message level (success, light-warning, warning, error, neutral)
 * @param buttons - The buttons that should be shown
 */
const showAlert = (title, message, level, buttons) => ({
    type: types_1.ALERT_SHOW,
    payload: {
        title, message, buttons, level,
    },
});
exports.showAlert = showAlert;
/**
 * Creates a redux action to hide a alert
 */
const hideAlert = () => ({
    type: types_1.ALERT_HIDE,
});
exports.hideAlert = hideAlert;
/**
 * Creates a redux action to lock a alert.
 * The alert will not be shown while the alert is locked
 */
const lockAlert = () => ({
    type: types_1.ALERT_LOCK,
});
exports.lockAlert = lockAlert;
/**
 * Creates a redux action to unlock a alert.
 * A locked alert will be shown after unlocking it
 */
const unlockAlert = () => ({
    type: types_1.ALERT_UNLOCK,
});
exports.unlockAlert = unlockAlert;
/**
 * Disables all alerts
 */
const setDisableAlerts = (disable) => ({
    type: types_1.ALERT_DISABLE,
    payload: disable,
});
exports.setDisableAlerts = setDisableAlerts;
