"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showLoaderModal = void 0;
const types_1 = require("./types");
/**
 * Shows/hdes a loader modal
 */
const showLoaderModal = (enabled) => ({
    type: types_1.MODAL_LOADER_SHOW,
    payload: enabled,
});
exports.showLoaderModal = showLoaderModal;
