import { TranslateUtil } from '@zorgi-mobile/shared-javascript';
import React from 'react';

import { useCustomer } from '../context/CustomerContext';

export function CustomerSelection() {
    const { customer, availableCustomers } = useCustomer();
    return (
        <>
            <option key="default" value="default">{TranslateUtil.translateKey('select_customer')}</option>
            {
                availableCustomers.map((availableCustomer) => (
                    <option
                        key={availableCustomer.id}
                        selected={availableCustomer.id === customer?.id}
                        value={availableCustomer.id}
                    >
                        {availableCustomer.name}
                    </option>
                ))
            }
        </>
    );
}
