import { Button, Intent, Spinner } from '@blueprintjs/core';
import { Log, toStringSafe, TranslateUtil } from '@zorgi-mobile/shared-javascript';
import React, { useEffect, useState } from 'react';

import { CustomerService, OpenAPI } from '../api/generated';
import { AddCustomerDialog } from '../components/AddCustomerDialog';
import { CustomerTable } from '../components/CustomerTable';
import { LogisticsNavbar } from '../components/Navbar';
import { useCustomer } from '../context/CustomerContext';

export function CustomersOverviewScreen() {
    const [isAddingCustomer, setAddingCustomer] = useState(false);
    const { availableCustomers, setAvailableCustomers } = useCustomer();

    const loadData = async () => {
        try {
            const newCustomers = await CustomerService.getCustomers();
            setAvailableCustomers(newCustomers);
        } catch (e) {
            Log.warn('Failed to get customers', toStringSafe(e));
        }
    };

    useEffect(() => {
        if (!OpenAPI.TOKEN) {
            window.location.href = '/' as string;
        } else if (availableCustomers == null) {
            loadData();
        }
    }, [availableCustomers]);

    return (
        <div>
            <LogisticsNavbar active="customers" />
            <div style={{
                width: '80%',
                margin: 'auto',
            }}
            >
                <h1>{TranslateUtil.translateKey('customers')}</h1>
                <Button
                    onClick={() => setAddingCustomer(true)}
                    intent={Intent.PRIMARY}
                    icon="add"
                    text={TranslateUtil.translateKey('add_customer')}
                />
                <AddCustomerDialog
                    isOpen={isAddingCustomer}
                    onClose={() => {
                        loadData();
                        setAddingCustomer(false);
                    }}
                />
                {availableCustomers
                    ? (<CustomerTable customers={availableCustomers} />)
                    : (<div style={{ marginTop: 20 }}><Spinner /></div>)}
            </div>
        </div>
    );
}
