"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLanguage = void 0;
const types_1 = require("./types");
/**
 * Creates a redux action to set the language.
 */
const setLanguage = (language) => ({
    type: types_1.LANGUAGE_SET,
    payload: {
        isDefault: false,
        language,
    },
});
exports.setLanguage = setLanguage;
