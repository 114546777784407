import { Cell, Column, Table } from '@blueprintjs/table';
import { TranslateUtil } from '@zorgi-mobile/shared-javascript';
import React from 'react';

import { CustomerList } from '../api/generated';

interface CustomerTableProps {
    customers: CustomerList;
}

export function CustomerTable({ customers }: CustomerTableProps) {
    const idCellRenderer = (rowIndex: number) => (
        <Cell>{customers[rowIndex].id}</Cell>
    );
    const nameCellRenderer = (rowIndex: number) => (
        <Cell>{customers[rowIndex].name}</Cell>
    );

    return (
        <div style={{ margin: 10 }}>
            <Table numRows={customers.length}>
                <Column name={TranslateUtil.translateKey('id')} cellRenderer={idCellRenderer} />
                <Column name={TranslateUtil.translateKey('name')} cellRenderer={nameCellRenderer} />
            </Table>
        </div>

    );
}
