"use strict";
/**
 * Created by michielknevels on 22/09/17.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("../actions/types");
const TranslateUtil_1 = require("../util/translation/TranslateUtil");
const INITIAL_STATE = null;
/**
 * A reducer to help with translations
 */
exports.default = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types_1.LANGUAGE_SET: {
            const notDefaultOrEmptyState = !action.payload.isDefault || !state;
            if (notDefaultOrEmptyState) {
                const { language, isDefault } = action.payload;
                if (language) {
                    TranslateUtil_1.TranslateUtil.setLanguage(language, isDefault);
                    return language;
                }
            }
            return state;
        }
        default:
            return state;
    }
};
