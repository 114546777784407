import { Button, Classes, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { TranslateUtil } from '@zorgi-mobile/shared-javascript';
import React, { useState } from 'react';

import { CustomerService } from '../api/generated';

interface AddCustomerDialogProps {
    isOpen: boolean;
    onClose: Function;
}

export function AddCustomerDialog(props: AddCustomerDialogProps) {
    const [customerName, setCustomerName] = useState('');
    const [error, setError] = useState<string | null>(null);

    const doCustomerCreateRequest = () => {
        CustomerService.addCustomer({
            id: '',
            name: customerName,
        }).then(() => {
            props.onClose();
        }).catch((e) => {
            setError(e.message);
        });
    };

    return (
        <Dialog isOpen={props.isOpen}>
            <div className={Classes.DIALOG_HEADER}>
                <h1>{TranslateUtil.translateKey('add_customer')}</h1>
            </div>
            <div className={Classes.DIALOG_BODY}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormGroup
                        intent={Intent.DANGER}
                        helperText={error}
                        label={TranslateUtil.translateKey('enter_customer_name')}
                        labelFor="device-name-input"
                        labelInfo={`(${TranslateUtil.translateKey('required')})`}
                    >
                        <InputGroup
                            onChange={(ev) => { setCustomerName(ev.target.value); }}
                            id="device-name-input"
                            placeholder={TranslateUtil.translateKey('enter_customer_name')}
                        />
                    </FormGroup>
                    <Button
                        onClick={() => {
                            doCustomerCreateRequest();
                        }}
                        disabled={customerName.length === 0}
                        style={{ alignSelf: 'flex-end' }}
                    >
                        {TranslateUtil.translateKey('add')}
                    </Button>
                </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <Button onClick={() => { props.onClose(); }}>{TranslateUtil.translateKey('close')}</Button>
            </div>
        </Dialog>
    );
}
