"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toStringSafe = exports.TextUtil = void 0;
const CommonUtils_1 = require("./CommonUtils");
const Log_1 = require("./Log");
/**
 * A util for text
 */
class TextUtil {
    /**
     * Capitalizes a string
     * @param string
     */
    static capitalize(string) {
        if (!string || string.length === 0) {
            return '';
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    /**
     * Fully capitalizes a string
     * @param string
     */
    static fullCapitalize(string) {
        if (!string) {
            return '';
        }
        return string.toUpperCase();
    }
    /**
     * Checks that given string is a valid "yymmdd" date string
     */
    static isValidDate(text) {
        return !!text.match(/^[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/);
    }
}
exports.TextUtil = TextUtil;
/**
 * Used to safely convert unknown or questionable objects into a string. Useful for logging caught exceptions.
 * The problem with just doing JSON.stringify is that some Error types will return "{}"". Because their internal
 * implementation is not serializable and will not be displayed correctly using JSON.stringify.
 */
function toStringSafe(something) {
    try {
        if (typeof something === 'string') {
            return something;
        }
        if ((0, CommonUtils_1.hasStack)(something)) {
            return something.stack;
        }
        if ((0, CommonUtils_1.hasToString)(something)) {
            return something.toString();
        }
        return JSON.stringify(something);
    }
    catch (e) {
        Log_1.Log.warn('toStringSafe failed to convert something to a string:', something, e);
        return '';
    }
}
exports.toStringSafe = toStringSafe;
