import { TranslateUtil } from '@zorgi-mobile/shared-javascript';
import React, { ReactNode } from 'react';

import { useCustomer } from '../context/CustomerContext';

interface CustomerSelectHOCProps {
    children: ReactNode;
}

export function CustomerSelectHOC(props: CustomerSelectHOCProps) {
    const customerContext = useCustomer();

    if (customerContext.customer) {
        return <div>{props.children}</div>;
    }
    return (
        <div style={{
            width: '80%',
            margin: 'auto',
        }}
        >
            <h1>{TranslateUtil.translateKey('select_a_customer')}</h1>
        </div>
    );
}
